import React,{ useEffect } from 'react';
import { getCatalogListAdmin } from 'library/catalog/get';
import {SelectPricelistModalLink} from 'components/selectPricelistModal';
import { useStyles } from './Style';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BrushIcon from '@material-ui/icons/Brush';
import clsx from 'clsx';
import { Link } from "react-router-dom";
import BookmarkIcon from '@material-ui/icons/Bookmark';
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Tooltip} from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ConfirmationDialog from 'components/modals/ConfirmationDialog';
import {apiDeleteCatalog} from 'library/catalog/update';
import { useDispatch } from 'react-redux';

export default function CatalogList() {
  const classes = useStyles();
  const dispatch = useDispatch();
	const [catalogList, setCatalogList] = React.useState([]);
  const [alert, setAlert] = React.useState({open: false, title: "", body: "", onConfirm: null});
  const [filter, setFilter] = React.useState({active: true, text: ""});
  const [pricelistAction, setPricelistAction] = React.useState({active: false, catalog: ""});
  
  useEffect(() => {
    getCatalogListAdmin().then(setCatalogList);
  },[]);

  const passes_filter = ((catalog) => {
    var show = true;
    if (filter.active) {
      show = (catalog.is_current === filter.active);
    }
    if (filter.text !== "") {
      show = catalog.name.includes(filter.text);
    }
    return show;
  });

  const didCancelPricelist = (pricelist) => {
    setPricelistAction({active: false, catalog: null});
  }
  const startGetDesignTXT = (catalog_id) => {
    setPricelistAction({active: true, catalog: catalog_id, action: "design"});
  }
  const startGetStockPDF = (catalog_id) => {
    setPricelistAction({active: true, catalog: catalog_id, action: "stock"});
  }
  const deleteCatalogInServer = (catalog_id) => {
      apiDeleteCatalog(catalog_id).then(r => {
        if (r.status === "success") {
          setCatalogList(catalogList.filter(catalog => catalog.id !== catalog_id));
          dispatch({type: "SHOW_MESSAGE", payload: {severity: "success", message: "Catalog deleted"}});
        } else if (r.status === "error") {
          dispatch({type: "SHOW_MESSAGE", payload: {severity: "error", message: r.error}});
        }
      }
    );
  }

	return (
    <div>
      <h1 className={classes.text_h1}>Catalogs</h1>
      <div className={classes.filter_container}>
        <ToggleButton value="current_catalog" className={classes.filter_current_catalog} aria-label="centered" onChange={e=>{setFilter({...filter, active: !filter.active})}}>
          <Tooltip title="Current catalogs" aria-label="add">
            { filter.active ? 
            <BookmarkIcon style={{color: "#5995c9"}} />
            :
            <BookmarkBorderIcon style={{color: "#5995c9"}} />
            }
          </Tooltip>
        </ToggleButton>
        <TextField 
          label="Search"
          className={classes.padding_text_field}
          onChange={e=>{setFilter({...filter, text: e.target.value})}}
        />
      </div>
      <div className={classes.catalog_list_cards}>
          {catalogList.map((catalog, index) => ( passes_filter(catalog) ?
            <CatalogListCard 
              details={catalog}
              key={index}
              onGetDesignLS={startGetDesignTXT}
              onGetStockLS={startGetStockPDF}
              onDelete={e=>{setAlert({
                open: true,
                title: "Delete Catalog",
                body: `Are you sure you want to delete the catalog "${catalog.name}"?`,
                onConfirm: () => {deleteCatalogInServer(catalog.id); setAlert({open: false, title: "", body: "", onConfirm: null})}
              })}}
            />
            : <React.Fragment key={index}></React.Fragment>
          ))}
      </div>
    <SelectPricelistModalLink 
      active={pricelistAction.active && pricelistAction.action === "design"}
      linkBase={process.env.REACT_APP_WLS_API_URL + "/api/catalog/preview/txt/" + pricelistAction.catalog}
      onClose={didCancelPricelist}
      classes={classes}
      />
    <SelectPricelistModalLink 
      active={pricelistAction.active && pricelistAction.action === "stock"}
      linkBase={process.env.REACT_APP_WLS_API_URL + "/api/catalog/stocks/pdf/" + pricelistAction.catalog}
      onClose={didCancelPricelist}
      classes={classes}
      />
      <ConfirmationDialog
        open={alert.open}
        title={alert.title}
        body={alert.body}
        onClose={() => setAlert({open: false, title: "", body: ""})}
        onConfirm={alert.onConfirm}
      />
    </div>
  );
}


const cardStyles = makeStyles(((theme) => ({
  root: {
    width: 275,
    backgroundColor: theme.wls.mainBg.contrast,
    margin: "5px",
  },
  pos: {
    marginBottom: 12,
  },
  button:{
    borderRadius:"0",
    margin:"0.2em 0",
  },
  deleteButton: {
    color: theme.palette.error.dark,
  },
})));

function CatalogListCard({details, onGetDesignLS, onGetStockLS, onDelete}) {
  const classes = cardStyles();

  return (
    <Card className={classes.root}>
      <CardHeader
        title={details.name}
        subheader={details.brand}
        action={
          <IconButton
            className={classes.deleteButton}
            onClick={onDelete}
            >
            <DeleteOutlineIcon />
          </IconButton>
        }
      />
      <CardContent>
        <Typography className={classes.pos} color="textSecondary">
          {details.season} - {details.catalog_type}
        </Typography>
        <Typography variant="body2" component="p">
          {details.num_items} items
        </Typography>
        </CardContent>
      <CardActions>
        <Link to={`/admin/catalog/list/${details.id}`}>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonExcel)}
            startIcon={<VisibilityIcon />}
          >
            Web
          </Button>
          </Link>
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonPDF)}
            startIcon={<PictureAsPdfIcon />}
            href={`${process.env.REACT_APP_WLS_API_URL}/api/catalog/preview/pdf/${details.id}`}
          >
            PDF
          </Button>
          {details.catalog_type === 'initial' ?
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonStock)}
            startIcon={<BrushIcon />}
            onClick={() => onGetDesignLS(details.id)}
          >
            Design
          </Button>
          : details.catalog_type === 'repo' &&
          <Button
            variant="contained"
            className={clsx(classes.button, classes.buttonStock)}
            startIcon={<ListAltIcon />}
            onClick={() => onGetStockLS(details.id)}
          >
            Stock
          </Button>
          }
      </CardActions>
    </Card>
    )
}